import React, { useState, useEffect } from "react";
import { Table, Space, message, Button, Tabs, Form, Input } from "antd";
import axios from "axios";

// const defaultEND = "http://127.0.0.1:7001/api";
const defaultEND = "https://api.anglis.com.br/api";

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT || defaultEND;

const GetPeople = (props) => {
  const id = props.id;
  //   const modalData = props.modalData;

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [currentTab, setCurrentTab] = useState("1");

  const handleDelete = async (recordId) => {
    try {
      await axios.delete(`${API_ENDPOINT}/people/remover/${recordId}`);
      message.success("Pessoa removida com sucesso!");
      fetchData();
    } catch (error) {
      message.error("Falha ao desativar pessoa");
      console.error("Error deleting person:", error);
    }
  };

  // Modify the columns array to add the delete column

  const columns = [
    {
      title: "Nome",
      dataIndex: "nome",
      key: "nome",
    },
    {
      title: "Idade",
      dataIndex: "idade",
      key: "idade",
    },
    {
      title: "Altura",
      dataIndex: "altura",
      key: "altura",
    },
    {
      title: "Peso",
      dataIndex: "peso",
      key: "peso",
    },
    {
      title: "Patologia",
      dataIndex: "patologia",
      key: "patologia",
    },
    {
      title: "Ativo",
      dataIndex: "active",
      key: "active",
      render: (active) => (active ? "Ativo" : "Inativo"),
    },
    {
      title: "Código",
      dataIndex: "codId",
      key: "codId",
    },
    // ... existing columns ...
    {
      title: "Ações",
      key: "actions",
      render: (_, record) => (
        <Button type="primary" danger onClick={() => handleDelete(record._id)}>
          Excluir
        </Button>
      ),
    },
  ];

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${API_ENDPOINT}/people/getAll/${id}`);
      setData(response.data);
    } catch (error) {
      message.error("Erro ao buscar dados");
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Space direction="vertical" style={{ width: "100%" }}>
      <div
        style={{ display: "flex", justifyContent: "flex-end", padding: "8px" }}
      ></div>

      <Tabs activeKey={currentTab} onChange={(key) => setCurrentTab(key)}>
        <Tabs.TabPane tab="Lista de Pessoas" key="1">
          <Table
            columns={columns}
            dataSource={data}
            loading={loading}
            rowKey="id"
            scroll={{ x: true }}
          />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Adicionar Pessoa" key="2">
          <Form
            layout="vertical"
            onFinish={async (values) => {
              try {
                await axios.put(
                  `${API_ENDPOINT}/people/cadastrar/${id}`,
                  values
                );
                message.success("Pessoa adicionada com sucesso!");

                setCurrentTab("1");

                fetchData();
              } catch (error) {
                message.error("Erro ao adicionar pessoa");
                console.error("Error adding person:", error);
              }
            }}
          >
            <Form.Item label="Nome" name="nome" rules={[{ required: true }]}>
              <Input />
            </Form.Item>
            <Form.Item label="Idade" name="idade" rules={[{ required: true }]}>
              <Input type="number" />
            </Form.Item>
            <Form.Item label="Peso" name="peso" rules={[{ required: true }]}>
              <Input type="number" />
            </Form.Item>
            <Form.Item
              label="Altura"
              name="altura"
              rules={[{ required: true }]}
            >
              <Input type="number" />
            </Form.Item>
            <Form.Item
              label="Patologia"
              name="patologia"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
            <Button type="primary" htmlType="submit">
              Adicionar
            </Button>
          </Form>
        </Tabs.TabPane>
      </Tabs>
    </Space>
  );
};

export default GetPeople;
